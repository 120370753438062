.taskBar {
  --height: 40px;
  --radius: 20px;
  --background: var(--color-grey-4);

  display: flex;
  align-items: center;
}

.taskBar_horizontal {
  .taskBarTitle {
    display: flex;
    align-items: center;
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    margin-right: 2px;
    background: var(--background);
    height: var(--height);
    padding-left: var(--radius);
    padding-right: 10px;
    font-size: 0.65rem;
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;
  }
  .taskBadgeContainer {
    background: var(--background);
    padding-left: 1rem;
    padding-right: var(--radius);
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    height: var(--height);
    gap: 1rem;
    display: flex;
    align-items: center;
  }
}

.taskBar_vertical {
  .taskBarTitle {
    display: none;
    white-space: nowrap;
  }
  .taskBadgeContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.taskBadgeLink {
  color: var(--color-fg-default);
  font-size: 0.875rem;
  text-align: left;

  &[disabled] {
    cursor: default;
    color: var(--color-grey-3);
    &:hover {
      text-decoration: none;
    }
  }
}

.taskBadge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  > svg {
    font-size: 1rem;
    color: var(--color-grey-3);
  }
}

.taskBadge__text {
  white-space: nowrap;
}

.taskBadge__badge {
  :global .MuiBadge-badge {
    --size: 24px;
    width: var(--size);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    position: relative;
    transform: none;
  }
}

.taskBadge__badge__noEntries,
.taskBadge__badge__priorityNormal {
  :global .MuiBadge-badge {
    background: #60646b26;
    color: var(--color-grey-1);
  }
}
.taskBadge__badge__priorityMedium {
  :global .MuiBadge-badge {
    background: var(--color-orange-1);
    color: white;
  }
}
.taskBadge__badge__priorityHigh {
  :global .MuiBadge-badge {
    background: var(--color-red-1);
    color: white;
  }
}
