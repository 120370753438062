@import "src/assets/scss/_breakpoints";

.container {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  min-height: 100%;
  padding: 0.5rem;
}

.navigationGrid {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto 1fr;
}

.navigationGridBody {
  width: 100%;
  height: auto;
  display: grid;
  grid-auto-flow: row;
}

.navigationGridHeader {
  text-align: left;

  h1 {
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.25;
  }
}

.column {
  width: 100%;
  height: 100%;
  padding: 0.5rem;

  h2 {
    margin: 0;
    padding: 0 0 1rem 0;
    line-height: 1.125;
    font-weight: 500;
    font-size: 1.25rem;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4rem;
  justify-content: space-between;

  p {
    margin: 0;
  }

  img {
    width: 50%;
    height: auto;
    margin: 0 auto;
    max-width: 150px;
  }
}

.taskCard {
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: initial;
}

.taskCardTitle {
  font-size: 1.125rem;
  line-height: 1.125;
  word-wrap: break-word;
  margin-bottom: 1rem;
  hyphens: none;
}

.taskCardLink {
  color: var(--color-grey-3);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: var(--transition);
  margin-top: 1rem;
  justify-content: space-between;
  width: 100%;

  &:hover {
    cursor: pointer;
    color: var(--color-grey-1);
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .navigationGrid {
    width: 100%;
    height: 100%;
  }

  .navigationGridBody {
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .navigationGridHeader {
    padding: 1rem;

    h1 {
      font-size: 1.75rem;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    height: 100%;

    &.variant_default {
      grid-template-columns: 3fr 1fr;
    }

    &.variant_equalColumns {
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
    }
  }

  .column {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .navigationGrid {
    overflow: auto;
  }

  .navigationGridBody {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
  }
}
