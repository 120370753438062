$small-font-size: 0.75rem;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-5);
  padding: 5px 10px;

  h2 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    padding: 0;
    margin: 0;
  }
}

.items {
  padding: 10px 10px 0 10px;
}

.footer {
  border-top: 1px solid var(--color-grey-5);
  padding: 10px 10px 5px 10px;
  font-size: $small-font-size;
  line-height: 1;
}

.item {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.statusBox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.statusInfo {
  width: 210px;
}

.itemTitle {
  line-height: 1;
}

.lastSyncedAt {
  font-size: $small-font-size;
  color: var(--color-grey-3);
}

.runningStatus {
  font-size: $small-font-size;
  color: var(--color-blue-1);
}

.errorStatus {
  font-size: $small-font-size;
  color: var(--color-red-1);
}
