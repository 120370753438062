@import "src/assets/scss/_breakpoints";

.currentEmployee {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 0;
  line-height: 1.5rem;
  color: var(--color-green-2);
  border-radius: var(--border-radius);
}
