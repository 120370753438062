.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.moreLoadingInfo {
  color: var(--color-grey-1);
  max-width: 200px;
  font-size: 0.75rem;
  text-align: center;
}
