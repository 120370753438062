.container {
  display: flex;
  align-items: center;
}

.locationIndicator {
  display: inline-flex;
}

.no_location {
  color: var(--color-red-1);
}

.red {
  color: var(--color-red-1);
}

.orange {
  color: rgb(var(--color-orange-1-rgb));
}

.green {
  color: rgb(var(--color-green-1-rgb));
}

.blue {
  color: rgb(var(--color-blue-1-rgb));
}

.grey {
  color: rgb(var(--color-grey-1-rgb));
}
