@import "src/assets/scss/_breakpoints";

.container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-auto-flow: row;

  &__content {
    padding: var(--content-padding);
    overflow: auto;

    &.noPadding {
      padding: 0;
    }
  }
}
