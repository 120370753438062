.base {
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.heading {
  @extend .base;
  margin: 0;
  padding-bottom: 1rem;
}

.tableCell {
  @extend .base;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.sticky {
  margin: 0;
  padding-bottom: 0;
  position: sticky;

  > span {
    background-color: var(--main-bg);
    display: block;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    background: linear-gradient(
      to top,
      rgba(var(--main-bg-rgb), 1) 50%,
      rgba(var(--main-bg-rgb), 0) 100%
    );
    height: 1rem;
    width: 100%;
  }
  &::after {
    transform: scaleY(-1);
  }

  &:first-child::before {
    background: var(--main-bg);
  }
}
