@import "src/assets/scss/_breakpoints";

.group {
  --icon-size: 20px;
  --gap: 0.5rem;

  h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;

    .icon {
      display: flex;
      align-items: center;
      width: var(--icon-size);
      color: var(--color-green-1);

      svg {
        width: 100%;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.item {
  padding: 1rem;
  line-height: 1.25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
  border-radius: var(--border-radius);
  font-weight: 500;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    .icon {
      color: var(--color-grey-1);
    }
  }

  .icon {
    display: inline-flex;
    align-items: center;
    width: 24px;
    color: var(--color-grey-4);
    transition: var(--transition);
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .group {
    padding: 1rem;
    h2 {
      padding: 0 0 0.5rem 0;
      font-size: 0.875rem;
    }
    > div {
      padding-left: calc(var(--icon-size) + var(--gap));
      display: block;
    }
  }

  .item {
    margin-left: -0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
  }
}
