@import "src/assets/scss/_breakpoints";
.layout {
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: radial-gradient(var(--main-bg), var(--color-grey-4));
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.logoContainer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding: 3rem 1rem 3rem 1rem;
}

.logo {
  display: flex;
  align-items: flex-start;
  font-size: 1.5rem;

  img {
    width: 8em;
  }
}

.betaChip {
  font-weight: 500;
  margin-left: 0.5em;
  color: var(--color-green-1);
  font-size: 0.4em;
  height: 2em;
  display: inline-flex;
  align-items: center;
  background-color: rgba(var(--color-green-1-rgb), 0.15);

  >span {
    padding: 0 1em;
  }
}

.welcomeContainer {
  margin-bottom: auto;
  color: var(--color-grey-1);
  max-width: 510px;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__content {
    overflow: auto;
    flex-grow: 1;
    padding: 0 1rem;

    a {
      color: var(--color-blue-1);
      text-decoration: underline;
    }
  }

  &__title {
    text-align: left;
    line-height: 1.25;
    font-size: 1.5rem;

    >span {
      display: inline-flex;
    }
  }

  &__actions {
    padding: 1rem 1rem 80px 1rem;

    button {
      width: 100%;
    }
  }
}

.animationContainer {
  --lawnmower-width: 120px;
  position: fixed;
  bottom: 0;
  width: 100%;
  pointer-events: none;

  img {
    position: relative;
    height: auto;
    width: var(--lawnmower-width);
    animation-name: drive;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .layout {
    align-items: center;
  }

  .container {
    flex-direction: row;
    align-items: center;
    padding: 0 4rem;
  }

  .logoContainer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    margin-top: 0;
    margin-right: 4rem;
    padding-right: 4rem;
    border-right: 2px solid var(--color-grey-4);
  }

  .logo {
    font-size: 1.75rem;
  }

  .welcomeContainer {
    max-width: 620px;
    margin-bottom: 0;
    font-size: 1rem;

    &__content {
      padding: 0;
    }

    &__title {
      font-size: 2rem;
    }

    &__actions {
      padding: 1rem 0 0 0;

      button {
        width: auto;
      }
    }
  }

  .animationContainer {
    --lawnmower-width: 150px;

    img {
      animation-duration: 10s;
      height: auto;
      width: var(--lawnmower-width);
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .logo {
    font-size: 2rem;
  }
}

@keyframes drive {
  0% {
    left: 0;
    transform: scaleX(1);
  }

  35% {
    transform: scaleX(1) rotateZ(0);
  }

  50% {
    left: calc(100% - var(--lawnmower-width));
    transform: scaleX(-1) rotateZ(-10deg);
  }

  55% {
    transform: scaleX(-1) rotateZ(0);
  }

  85% {
    transform: scaleX(-1);
  }

  100% {
    left: 0;
    transform: scaleX(1);
  }
}
