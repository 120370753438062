@import "src/assets/scss/_breakpoints";

.header {
  padding-right: var(--content-padding);
  padding-left: calc(var(--header-sidebar-toggle-size) + 0.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: var(--header-size);
  z-index: 100;

  &__left {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    &__menus {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }
}

.logo {
  flex-shrink: 0;
  height: 20px;
  display: block;

  > img {
    height: 100%;
    width: auto;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .header {
    padding-left: var(--content-padding);
  }
}
