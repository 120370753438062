html {
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: var(--color-grey-1);
  height: 100%;
  background: var(--main-bg);

  font-family: var(--font-family);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-decoration-skip-ink: auto;
  hyphens: auto;
}

body {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  font-weight: inherit;
  border: none;
  text-decoration: none;
}
