.container {
  width: 100%;
  height: 100%;
  position: relative;

  &.withPadding {
    &.type_page {
      padding: var(--content-padding);
    }
    &.type_dialog {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }

  &.withBackground {
    background-color: var(--main-bg);
  }
}

.page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 48px 1fr;
  gap: 1rem;

  &__toolbar {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      gap: 1rem;
      flex-grow: 1;
    }

    &__skeleton {
      height: 100%;
      display: block;
      flex-shrink: 0;
    }
  }

  &__content {
    height: 100%;
  }
}

.dialog {
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__intro {
    display: flex;
    display: none;
    flex-direction: column;
    gap: 0.125rem;
    flex-shrink: 0;
  }
  &__content {
    flex-grow: 1;
  }
  &__footer {
    flex-shrink: 0;
    height: 36px;
  }
}

.toolbarContent {
  width: 100%;
  height: 100%;
  padding-top: 1rem;

  &__skeleton {
    width: 100%;
    height: 100%;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
