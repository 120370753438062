#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
