.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;
  color: var(--color-grey-1);

  h1 {
    line-height: 1.25;
  }

  p {
    text-align: center;
  }

  img {
    max-width: 100%;
  }
}

.contacts {
  font-size: 0.875rem;
  color: var(--color-grey-2);
}

.saw {
  width: 300px;
}
