.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;

  &.isDesktop {
    grid-template-rows: auto 1fr;
  }
}

.tabBar {
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-grey-4);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;

  .title {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }

  .tabs {
    :global(.MuiTabs-root) {
      min-height: auto;
    }

    :global(.MuiTab-root) {
      min-height: auto;
      height: 32px;
      font-size: 0.75rem;
    }
  }
}

.titleBar {
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-grey-4);
  height: var(--header-size);
  padding: 0 var(--content-padding);
  position: relative;
  display: flex;
  align-items: center;

  .pageTitle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    pointer-events: none;
  }
}

.content {
  overflow: auto;
}
