.container {
  height: 100%;
}

.drawer {
  height: 100%;
  --sidebar-background-color: var(--color-grey-1);
  --sidebar-color: var(--color-white);
}

.drawer__paper {
  background-color: var(--sidebar-background-color);
  border: 0;
  color: var(--sidebar-color);
  width: var(--sidebar-width-s);
  position: relative;
  overflow-x: hidden;
}

.drawer__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drawerHeader {
  height: var(--header-size);
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
  flex-shrink: 0;
  padding: 0 18px;

  > span {
    transition: var(--transition);
    font-size: 1rem;
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
  }
}

.toggle_temporary {
  height: var(--header-size);
  width: var(--header-sidebar-toggle-size);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1000;
}

.temporary {
  .drawer__paper {
    z-index: 1200;
  }
}

.permanent {
  .drawer {
  }

  .drawer__paper {
    width: var(--header-size);
    overflow-x: hidden;
    transition: var(--transition);
    z-index: 1000;
  }

  .drawerHeader {
    color: var(--sidebar-color);
    background-color: var(--sidebar-background-color);
    width: var(--header-size);
    justify-content: center;
    padding: 0 1rem;

    svg {
      width: 20px;
    }
  }

  &.open {
    .drawerHeader {
      width: var(--sidebar-width);
      justify-content: space-between;
      > span {
        width: auto;
      }
    }

    .drawer__paper {
      width: var(--sidebar-width);
    }
  }
}
