@import "../../../../../assets/scss/breakpoints";
.item {
  padding-left: var(--sidebar-menu-item-padding);
  padding-right: var(--sidebar-menu-item-padding);
  padding-top: 0;
  padding-bottom: 0;

  .button {
    border-radius: var(--border-radius);
    padding: 0;
    color: var(--color-grey-4);
    position: relative;

    .text {
      white-space-collapse: break-spaces;
      text-wrap: wrap;

      > p {
        line-height: 1.25;
      }
    }

    .icon {
      width: var(--header-size);
      aspect-ratio: 1/1;
      min-width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: currentColor;

      svg {
        width: 20px;
      }
    }

    .chevron {
      transition: var(--transition);
      transform: rotate(180deg);
      &.collapsed {
        transform: rotate(0deg);
      }
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.15);
      color: var(--color-white);

      &:hover,
      &.hover {
        background-color: rgba(255, 255, 255, 0.15);
        color: var(--color-white);
      }

      .text {
        width: 100%;
      }
    }

    &.active:not(.collapsible) {
      pointer-events: none;
      cursor: default;
      .text > p {
        font-weight: 600;
      }
    }

    &:hover,
    &.hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .item {
    padding-left: var(--sidebar-menu-item-padding-sm);
    padding-right: var(--sidebar-menu-item-padding-sm);

    .button {
      .text {
        white-space-collapse: preserve;
        text-wrap: nowrap;
        margin-left: var(--sidebar-menu-item-padding-sm);
      }
      .icon {
        width: calc(
          var(--header-size) - (2 * var(--sidebar-menu-item-padding-sm))
        );
      }
    }
  }
}
