@import "_breakpoints";

:root {
  --main-bg-rgb: 246, 246, 246;
  --main-bg: rgb(var(--main-bg-rgb));
  --section-bg: #ebebeb;
  --border-color: #cfcfcf;
  --border-color-light: #f5f5f5;

  --color-red-1: #a32238;
  --color-red-1-rgb: 163, 34, 56;
  --color-red-2: #d6002e;
  --color-red-3: #f1dee1;
  --color-red-4: #fcf8f9;
  --color-red-5: #a2727a;

  --color-orange-1-rgb: 240, 168, 0;
  --color-orange-1: #f0a800;
  --color-orange-2: #ffe6b5;
  --color-orange-3: #ffeecc;

  --color-blue-1: #1976d2;
  --color-blue-2: #ddeaf8;
  --color-blue-5: #ddeaf8;
  --color-blue-1-rgb: 25, 118, 210;

  --border-radius: 5px;
  --border-radius-xl: 20px;
  --color-white-rgb: 255, 255, 255;
  --color-white: rgb(var(--color-white-rgb));
  --color-white-dimmed: rgb(250, 250, 250);
  --color-black: #000000;
  --color-green-1-rgb: 0, 140, 88;
  --color-green-1: rgb(var(--color-green-1-rgb));
  --color-green-1: #008c58;
  --color-green-2: #33a379;
  --color-green-3: #5fb696;
  --color-green-4: #99d1bc;
  --color-green-5: #cce8de;

  --color-grey-1-rgb: 96, 100, 107;
  --color-grey-1: rgb(var(--color-grey-1-rgb));
  --color-grey-2: #797e86;
  --color-grey-3-rgb: 158, 158, 158;
  --color-grey-3: rgb(var(--color-grey-3-rgb));
  --color-grey-4: #ebebeb;
  --color-grey-5: #f2f2f2;

  --color-absence-sickness-rgb: 166, 33, 60;
  --color-absence-school-rgb: 0, 75, 140;
  --color-absence-training-rgb: 75, 140, 0;
  --color-absence-vacation-rgb: 96, 100, 107;
  --color-absence-leisure-time-account-rgb: 96, 100, 107;

  --transition-timing: cubic-bezier(0, 0, 0.2, 1);
  --transition: all 225ms var(--transition-timing) 0ms;

  --line-height-heading: 1.25;

  --font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  --box-shadow-default: 0 0 10px 0 rgb(0 0 0 / 5%);
  --box-shadow-8: 0 0 10px 0 rgb(0 0 0 / 0.2);

  --min-width-desktop: 1100px;

  --table-head-size: 40px;
  --table-colgroup-border-color: #dad4d4;

  --content-padding: 1rem;

  --header-size: 48px;
  --header-icon-button-size: 40px;
  --header-icon-button-padding: 8px;
  --header-sidebar-toggle-size: calc(
    var(--header-icon-button-size) +
      var(--content-padding) - var(--header-icon-button-padding)
  );
  --sidebar-width-s: 250px;
  --sidebar-width: 300px;
  --sidebar-menu-item-padding-sm: 6px;
  --sidebar-menu-item-padding: 8px;
}
