.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.fullScreen {
    width: 100%;
    height: 100%;
  }
}

.info {
  color: var(--color-grey-1);
  max-width: 300px;
  font-size: 0.75rem;
  text-align: center;
}

.animation {
  --lawnmower-width: 120px;
  --lawnmower-amount: 3;
  --duration: 1.75s;

  position: relative;
  display: flex;
  width: calc(var(--lawnmower-amount) * var(--lawnmower-width));
  mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 20%,
    rgba(0, 0, 0, 1) 35%,
    rgba(0, 0, 0, 1) 65%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 0) 100%
  );

  img {
    width: var(--lawnmower-width);
    height: auto;
    position: relative;
    animation-name: loading;
    animation-duration: var(--duration);
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &:nth-child(1) {
      transform: translateX(0);
    }
    &:nth-child(2) {
      transform: translateX(-100%);
      animation-delay: calc(var(--duration) / var(--lawnmower-amount));
    }
    &:nth-child(3) {
      transform: translateX(-200%);
      animation-delay: calc(2 * var(--duration) / var(--lawnmower-amount));
    }
  }
}

@keyframes loading {
  0% {
    left: calc(-1 * var(--lawnmower-width));
  }
  50% {
  }
  100% {
    left: 100%;
  }
}
