@import "src/assets/scss/_breakpoints";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem var(--content-padding);
  background-color: var(--color-green-5);
  color: var(--color-green-1);
  font-weight: 600;
  width: 100%;
  height: var(--header-size);
  position: relative;
  z-index: 1100;
  box-shadow: var(--box-shadow-default);

  > svg {
    flex-shrink: 0;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.icon {
  flex-shrink: 0;
  display: inline-flex;
  border-radius: 50%;
  position: relative;
  padding: 0.25rem;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }

  &::before {
    background-color: rgba(var(--color-white-rgb), 0.4);
    border: 3px solid var(--color-green-4);
    filter: blur(2px);

    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    opacity: 0;
    transform: scale(0.75);
  }

  &::after {
    background-color: rgba(var(--color-white-rgb), 0.4);
  }

  svg {
    z-index: 10;
    position: relative;
    color: var(--color-green-1);
  }
}

.title {
  margin: 0 0.5rem;
  line-height: 1.25;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.65);
    opacity: 0;
  }
}
