.base {
  text-transform: uppercase;
  width: 9rem;
}

.toolResponsibilityTaken {
  @extend .base;
  color: var(--color-blue-1);
  background-color: rgba(var(--color-blue-1-rgb), 0.15);
}

.toolResponsibilityReturned {
  @extend .base;
  color: var(--color-grey-1);
  background-color: rgba(var(--color-grey-1-rgb), 0.1);
}

.toolDataChanged {
  @extend .base;
  color: var(--color-blue-1);
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-1);
}

.toolPermanentDecommissioned {
  @extend .base;
  color: var(--color-red-2);
  background-color: var(--color-grey-5);
  border: 1px solid var(--color-red-2);
}

.toolPermanentRecommissioned {
  @extend .base;
  color: var(--color-green-2);
  background-color: var(--color-grey-5);
  border: 1px solid var(--color-green-2);
}

.unknown {
  @extend .base;
  color: var(--color-white);
  background-color: var(--color-red-1);
}
