.avatarButton {
  padding: calc(var(--header-icon-button-padding) / 2);
  &__avatar {
    width: calc(
      var(--header-icon-button-size) - var(--header-icon-button-padding)
    );
    height: calc(
      var(--header-icon-button-size) - var(--header-icon-button-padding)
    );
    background-color: var(--color-grey-1);
    font-size: 1rem;
  }
}

.activeAccount {
  padding: 0 1rem 0.5rem 1rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__label {
    font-size: 0.75rem;
    margin: 0;
  }

  &__name {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 0.125rem 0;
  }

  &__email {
    font-size: 0.875rem;
    line-height: 1;
    margin: 0;
  }

  &__logout {
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;

    span:first-child {
      margin-right: 0.125rem;

      svg {
        font-size: inherit;
      }
    }
  }
}

.inactiveAccounts {
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--color-grey-4);
  border-top: 1px solid var(--color-grey-4);

  &:empty {
    padding: 0;
    border-top: 0;
  }
}

.inactiveAccount {
  padding: 0.5rem 1rem;

  &__name {
    line-height: 1;
    margin: 0;
  }

  &__email {
    line-height: 1;
    font-size: 0.875rem;
    margin: 0;
    color: var(--color-grey-3);
  }
}
