.card {
  color: var(--color-grey-1);
}

.cardSegment {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  margin-top: 0.75rem;
  border-top: 1px solid var(--color-grey-5);

  > span {
    font-size: 0.75rem;
    font-weight: normal;
    color: var(--color-grey-3);
  }
}

.unread {
  font-weight: bold;
}
