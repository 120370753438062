@import "src/assets/scss/_breakpoints";

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.25;
  font-size: 1.25rem;

  >span {
    margin-right: 1rem;
    display: flex;
    align-items: center;

    >svg {
      margin-right: 0.5rem;
    }
  }
}

.draggable {
  cursor: move;
}

@media screen and (max-width: $breakpoint-sm) {
  .container :global {
    .MuiDialog-paper {
      margin: 0.5rem;
      width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .title {
    font-size: 1.5rem;
  }
}
