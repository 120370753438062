.footer {
  width: 100%;
  transition: var(--transition);
  padding-bottom: 18px;

  &__footnote {
    display: flex;
    align-items: center;
    width: 100%;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    transition: var(--transition);

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      height: 1.5rem;
      width: 18px;
      background: linear-gradient(
        to left,
        rgba(var(--color-grey-1-rgb), 1) 0%,
        rgba(var(--color-grey-1-rgb), 0) 100%
      );
    }

    .footerVersionTag {
      min-width: calc(var(--header-size) - (2 * 8px));
      flex-shrink: 0;
      text-align: center;
      display: inline-block;
      font-size: 11px;
      font-weight: 500;
      background-color: var(--color-grey-2);
      border-radius: 0.75rem;
      padding: 0.075rem 0.5rem;
    }

    .footerCopyright {
      font-size: 0.75rem;
      margin-left: 0.5rem;
      opacity: 0.75;
      color: var(--color-grey-4);
    }
  }
}

.footer_open {
  padding-left: 18px;
  padding-right: 18px;

  .footer__footnote {
    padding-left: 0;
    padding-right: 0;

    .footerVersionTag {
      width: auto;
    }
  }
}
