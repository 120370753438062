.container {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
}

.base {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template:
    "aside header"
    "aside main";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  position: relative;

  .main {
    grid-area: main;
    overflow: hidden;
  }

  .header {
    width: 100%;
    overflow: hidden;
    grid-area: header;
    border-bottom: 1px solid var(--color-grey-4);
    background-color: var(--color-white);
  }
  .aside {
    grid-area: aside;
    height: 100%;
    overflow: auto;
  }
}

