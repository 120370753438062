.header {
  display: flex;
  flex-direction: column;
  position: relative;

  --pad-x: var(--content-padding);
  --pad-y: calc(var(--content-padding));
}

.mobile {
  .titleBar {
    width: 100%;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey-4);
    height: var(--header-size);
    padding: 0 var(--pad-x);
    position: relative;
    display: grid;
    grid-template-columns: var(--header-size) 1fr var(--header-size);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1rem;
  }

  .actionBar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: var(--pad-y) var(--pad-x) 0 var(--pad-x);
  }
}

.desktop {
  flex-direction: row;

  .titleBar {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: auto;
    height: auto;
    padding: var(--pad-y) var(--pad-x) 0 var(--pad-x);
    margin-right: auto;
  }

  .backLinkContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: auto;
  }

  .backLink__label {
    font-size: 1rem;
  }

  .backLink__separator {
    opacity: 0.5;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  .actionBar {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: var(--pad-y) var(--pad-x) 0 var(--pad-x);
  }
}

.backLink {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.loadingBar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.125rem;
}
