.nav {
  white-space: nowrap;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  ul {
    padding-top: 0;
  }
}
