.stack {
  padding: 0;
}

.stackItem {
  padding: 4px 8px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}
