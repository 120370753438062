/*
 * Source:
 * https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc6CsTYl4BO.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic6CsTYl4BO.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc6CsTYl4BO.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2
 * https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2
 */

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-BlackItalic.woff2") format("woff2");
}
