.container {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  gap: 0.5rem;
}

.containerBlock {
  display: flex;
}
