@import "../../../../../assets/scss/breakpoints";
.group.collapsible {
  .group__items {
    position: relative;
    width: auto;
    background-color: var(--color-grey-1);
    color: var(--color-white);
    padding-left: var(--header-size);
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      height: calc(100% - 1rem);
      width: 2px;
      background-color: var(--color-white);
      opacity: 0.25;
      border-radius: 2px;
      left: calc(
        var(--header-size) / 2 + var(--sidebar-menu-item-padding) - 1px
      );
      top: 0.5rem;
    }
  }
  .groupItem {
    padding: 1rem;
    margin-left: -0.5rem;
    position: relative;
  }
}

.group.popover {
  .groupItem {
    padding: 0.5rem;
  }
}

.group__items.popover {
  width: 200px;
  padding-left: 5px;

  > div {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    background-color: var(--color-grey-1);
    color: var(--color-white);
    padding: 0.5rem;
    box-shadow: var(--box-shadow-8);
  }

  .groupTitle {
    font-size: 0.6rem;
    padding: 0.25rem 0.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.5;
  }
}

.group__items {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.groupItem {
  padding: 0.5rem 0.5rem;
  border-radius: var(--border-radius);
  line-height: 1.25;
  font-size: 0.875rem;
  text-wrap: wrap;
  position: relative;

  &.active {
    &,
    &:hover {
      font-weight: 700;
      color: var(--color-white);
      background-color: rgba(255, 255, 255, 0.15);
    }
    transition: var(--transition);
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .group.collapsible {
    .group__items {
      padding-left: var(--header-size);
      padding-right: var(--sidebar-menu-item-padding-sm);
      padding-top: var(--sidebar-menu-item-padding-sm);
      padding-bottom: var(--sidebar-menu-item-padding-sm);

      &::before {
        left: calc(var(--header-size) / 2 - 1px);
      }
    }
    .groupItem {
      padding: 0.5rem;
      position: relative;
    }
  }
}
