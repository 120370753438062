.tableContainer {
  height: 100%;
}

.table {
  --head-size: var(--table-head-size);

  width: 100%;
  max-height: 100%;
  border-spacing: 0 0.5rem;
  border-collapse: separate;
  margin-top: -0.5rem;
}

.tableHead {
  &__row {
    position: sticky;
    top: 0;
    z-index: 5;
  }
}

.itemRow {
  &_outlined {
    .itemCell {
      border-top: 1px solid var(--color-grey-4);
      border-bottom: 1px solid var(--color-grey-4);
      background-color: var(--color-grey-5);

      &:first-child {
        border-left: 1px solid var(--color-grey-4);
      }
      &:last-child {
        border-right: 1px solid var(--color-grey-4);
      }
    }
  }
}

.itemCell {
  &:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  &:last-of-type {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.infoResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-grey-3);
  &__image {
    width: 300px;
  }
  &__text {
    width: 500px;
    text-align: center;
    font-size: 1.25rem;
  }
}
