.rangePicker {
  width: 100%;
  gap: 1rem;

  :global(.MuiMultiInputDateRangeField-separator) {
    display: none;
  }

  :global(.MuiFormControl-root) {
    margin: 0;
  }
}
