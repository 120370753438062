$width: 460px;
$box-shadow-width: 10px;

.container {
  position: relative;
}

.drawer {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: $width;
  top: 0;
  right: 0;
  overflow: hidden;
  padding-left: $box-shadow-width;
  pointer-events: none;
  visibility: hidden;

  .paper {
    width: 100%;
    height: 100%;
    margin-left: $width;
    overflow: auto;
    position: relative;
    transition: var(--transition);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 $box-shadow-width 0 rgb(0 0 0 / 20%);
    pointer-events: auto;
    display: flex;
    flex-direction: column;
  }

  &_open {
    .paper {
      margin-left: 0;
    }

    visibility: visible;
  }
}

.header {
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1.5rem;
  border-bottom: 1px solid var(--color-grey-5);

  h4 {
    margin: 0;
    line-height: 1.25;
    font-size: 1.125rem;
  }

  .closeBtn {
    flex-shrink: 0;
  }
}

.content {
  flex-grow: 1;
  overflow: auto;
}

.actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  &>*+* {
    margin-top: 0.3rem;
  }

  &>button {
    padding: 0.5rem;
  }
}

.segment {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--color-grey-5);

  >span {
    font-size: 0.75rem;
    color: var(--color-grey-3);
  }

  >div {
    font-weight: 500;
  }
}
